var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-main",
    [
      _c("date-dialog", {
        attrs: {
          isDialogVisible: _vm.isDateDialogVisible,
          dateRange: _vm.filter.dateRange,
        },
        on: {
          dialogAction: _vm.setDateRange,
          closeDialog: _vm.closeDateDialog,
        },
      }),
      _c(
        "el-container",
        { attrs: { direction: "vertical" } },
        [
          _c(
            "div",
            { staticClass: "menu-buttons" },
            [
              _c("el-button", { on: { click: _vm.openDateDialog } }, [
                _vm._v(
                  "Выбрать дату (" +
                    _vm._s(this.$data.filter.dateRange[0]) +
                    " - " +
                    _vm._s(this.$data.filter.dateRange[1]) +
                    ")"
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { data: _vm.clients },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "email", label: "Email" },
              }),
              _c("el-table-column", {
                attrs: { prop: "fullName", label: "ФИО" },
              }),
              _c("el-table-column", {
                attrs: { prop: "mobileNumber", label: "Телефон" },
              }),
              _c("el-table-column", {
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [
                        _c("el-input", {
                          attrs: { size: "mini", placeholder: "Поиск" },
                          model: {
                            value: _vm.filter.search,
                            callback: function ($$v) {
                              _vm.$set(_vm.filter, "search", $$v)
                            },
                            expression: "filter.search",
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "column-buttons" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.openDialog(scope.row)
                                  },
                                },
                              },
                              [_vm._v("ЛК")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          "hide-on-single-page": "",
          "page-size": _vm.filter.limit,
          layout: "prev, pager, next",
          total: _vm.count,
        },
        on: { "current-change": _vm.setCurrentPage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }