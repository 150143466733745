













































import moment from "moment";
import { debounce } from "@/helpers/helpers";
import { IFilterOptions } from "@/services/ApiService";
import { Vue, Component, Watch } from "vue-property-decorator";
import DateDialog from "@/components/dialogs/DateDialog.vue";

@Component({
    components: {
        "date-dialog": DateDialog,
    },
})
export default class Main extends Vue {
    private isDateDialogVisible: boolean = false;

    private data(): object {
        return {
            clients: [],
            count: 0,
            loading: false,
            filter: {
                search: "",
                page: 0,
                limit: 50,
                dateRange: [moment().startOf("month").format("YYYY-MM-DD"), moment().endOf("month").format("YYYY-MM-DD")],
            },
        };
    }

    private closeDateDialog() {
        this.isDateDialogVisible = false;
    }

    private openDateDialog() {
        this.isDateDialogVisible = true;
    }

    private setDateRange(dateRange: any[]) {
        this.$data.filter.dateRange = dateRange;

        this.closeDateDialog();
    }

    private get debouncedSearch() {
        return debounce(this.getClients, 300);
    }

    private async beforeMount() {
        await this.debouncedSearch(this.$data.filter);
    }

    private setCurrentPage(page: any) {
        this.$data.filter.page = page - 1;
    }

    private async openDialog(row: any) {
        const res = await this.$Api.Main.GetClientSession(row.email);

        if (res.status === "OK") {
            prompt("Ссылка для логина", `https://lk.osago.one/?sessionId=${res.sessionId}`);
        } else {
            alert(res.message);
        }

    }

    private async getClients(filter: IFilterOptions) {
        this.$data.loading = true;

        try {
            const { result } = await this.$Api.Main.GetClients(filter);

            this.$data.clients = result.data;
            this.$data.count = result.count;
        } catch (error) {
            alert(error.message || error);
        } finally {
            this.$data.loading = false;
        }
    }

    @Watch("filter", { deep: true })
    private onFilterChange() {
        this.debouncedSearch(this.$data.filter);
    }
}
